import { combineReducers } from "redux";
import menus from "./menus";
import categories from "./categories";
import banners from "./banners";
import contents from "./contents";
import settings from "./settings";
import meta from "./meta";
import assets from "./assets";
import jobs from "./jobs";
// import products from "./products";
// import snackbar from "./snackbar";
// import customers from "./customer";
// import orders from "./orders";
// import testimonials from "./testimonials";
// import cart from "./cart";

const appReducers = combineReducers({
  menus,
  categories,
  banners,
  contents,
  settings,
  meta,
  assets,
  jobs,
  // snackbar,
  // products,
  // customers,
  // orders,
  // testimonials,
  // cart,
});

export default appReducers;
