import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  dataUrl,
  getAssetsByName,
  getMenusByCategory,
  openUrlNewWindow,
} from "../../../helpers/common";
import "./styles.css";

const Header = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const menus = useSelector((state) => state.menus.menus);
  const topMenus = getMenusByCategory(menus, "top-menus");
  const assets = useSelector((state) => state.banners["assets"]);
  const websiteLogo = getAssetsByName(assets, "website-logo", {})?.image;

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const header = document.querySelector("header");
    const blankHeader = document.querySelector(".blank-header");
    const scrollTop = window.scrollY;

    if (scrollTop >= 100) {
      setIsHeaderVisible(false);
    } else {
      setIsHeaderVisible(true);
    }

    scrollTop >= 200
      ? header.classList.add("sticky")
      : header.classList.remove("sticky");

    scrollTop >= 200
      ? blankHeader.classList.add("show")
      : blankHeader.classList.remove("show");
  };

  const scrollToTop = () => {
    return window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const mobileMenuClick = (menu) => {
    openUrlNewWindow(menu);
    setShowMobileMenu(false);
  };

  return (
    <>
      <div className="blank-header"></div>
      <header className={`${!isHeaderVisible ? "header-gone" : ""}`}>
        <div className="container-fluid">
          <div className="header-sec">
            <div className="left-sec">
              <Link to="/" onClick={() => scrollToTop()}>
                <img
                  src={
                    websiteLogo
                      ? `${dataUrl}/banners/${websiteLogo}`
                      : "/images/logo.png"
                  }
                  alt="logo"
                  className="logo-img"
                />
              </Link>

              <div className="menu-sec">
                <ul>
                  {topMenus?.map((menu) => (
                    <li
                      onClick={() => scrollToTop()}
                      key={menu.id}
                      className={`${pathname === menu.url ? "active" : ""}`}
                    >
                      <Link
                        to={`${
                          menu?.open_in_new_window === "no" ? menu.url : "#"
                        }`}
                        onClick={() => openUrlNewWindow(menu)}
                      >
                        {menu.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="right-sec">
              <Link to="/say-hello" className="book-demo-btn">
                Book a demo
              </Link>

              <div className="solid-btn-sec">
                <Link
                  to="/get-notified"
                  onClick={() => setShowMobileMenu(false)}
                  className="my-btn"
                >
                  Get notified
                </Link>
              </div>

              <div
                className={`mob-menu-icon-sec ${
                  showMobileMenu ? "active" : ""
                }`}
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>

        <div className={`collapse-menu-sec ${showMobileMenu ? "show" : ""}`}>
          <ul>
            {topMenus.map((menu) => (
              <li
                key={menu.id}
                className={`${pathname === menu.url ? "active" : ""}`}
              >
                <Link
                  to={`${menu?.open_in_new_window === "no" ? menu.url : "#"}`}
                  onClick={() => mobileMenuClick(menu)}
                >
                  {menu.name}
                </Link>
              </li>
            ))}
            <li>
              <Link onClick={() => setShowMobileMenu(false)} to="/say-hello">
                Book a demo
              </Link>
            </li>
            
            <div className="solid-btn-sec">
              <Link
                onClick={() => setShowMobileMenu(false)}
                to="/get-notified"
                className="my-btn"
              >
                Get notified
              </Link>
            </div>
          </ul>
        </div>
      </header>
    </>
  );
};

export default Header;
