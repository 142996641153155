
import {
  GET_CONTENTS_SUCCESS,
  GET_CONTENTS_FAILURE,
} from '../constants/actionTypes';

const INITIAL_STATE = {
  'partial_contents': [],
  'what_we_do': [],
  'why_teemuno': [],
  'studios_capabilities': [],
  'join_us': [],
  error: null
};

export default function contents(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CONTENTS_SUCCESS:
      return {...state, [action.payload.contentType]: action.payload.data};
    case GET_CONTENTS_FAILURE:
    default:
      return state;
  }
}
