import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getBanners } from "../../../actions/banners";
import { getContents } from "../../../actions/contents";
import { getJobs } from "../../../actions/jobs";
import { getMenus } from "../../../actions/menus";
import { getSettings } from "../../../actions/settings";
import {
  dataUrl,
  getAssetsByName,
  getMenusByCategory,
  getSettingByKey,
  openUrlNewWindow,
} from "../../../helpers/common";
import "./styles.css";

const Footer = () => {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const location = useLocation();
  const pathname = location.pathname;
  const dispatch = useDispatch();
  const menus = useSelector((state) => state.menus.menus);
  const footerMenus = getMenusByCategory(menus, "footer-menus");
  const settings = useSelector((state) => state.settings.data);
  const copyrightMsg = getSettingByKey(
    settings,
    "footer_copyright_message"
  )?.value;

  const assets = useSelector((state) => state.banners["assets"]);
  const websiteLogo = getAssetsByName(assets, "website-footer-logo", {})?.image;

  useEffect(() => {
    dispatch(getSettings());
    dispatch(getMenus());
    dispatch(getContents("what_we_do"));
    dispatch(getContents("why_teemuno"));
    dispatch(getContents("studios_capabilities"));
    dispatch(getContents("join_us"));
    dispatch(getContents("partial_contents"));
    dispatch(getJobs());
    dispatch(getBanners("assets"));
  }, [dispatch]);

  return (
    <>
      <div className="footer-sec">
        <div className="container-fluid">
          <div className="inner-container">
            <div className="left-sec">
              <ul className="footer-menu-links">
                {footerMenus.map((menu) => (
                  <li
                    onClick={() => scrollToTop()}
                    key={menu.id}
                    className={menu.url === pathname ? "active" : ""}
                  >
                    <Link
                      onClick={() => openUrlNewWindow(menu)}
                      to={`${
                        menu?.open_in_new_window === "no" ? menu.url : "#"
                      }`}
                    >
                      {menu.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="right-sec">
              <div className="copyright-sec">
                <p>{copyrightMsg}</p>
              </div>
              <Link to="/">
                <img
                  src={
                    websiteLogo
                      ? `${dataUrl}/banners/${websiteLogo}`
                      : "/images/footer-logo.png"
                  }
                  alt="website logo"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
