// export const apiUrl = "https://products.zarrtechnologies.com/teemuno/backend/api/web";
// export const dataUrl = "https://products.zarrtechnologies.com/teemuno/backend/data";

export const apiUrl = "https://teemuno.com/backend/api/web";
export const dataUrl = "https://teemuno.com/backend/data";

export const capitalize = (str) =>
  `${str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()}`;

export const sortFn = (el1, el2, sortKey = "display_order") =>
  el1[sortKey] > el2[sortKey] ? 1 : -1;

export const preferredDeliveryCategoryId = [52];

export const imageUrl = (url, prefix = "products", sizePrefix = "120x120-") =>
  `${dataUrl}/${prefix}/${sizePrefix}${url}`;

export const getMenusByCategory = (menus, categoryName) =>
  menus.filter((menu) => menu.category === categoryName);

export const productImageUrl = (
  url,
  prefix = "products",
  id = "1",
  sizePrefix = "120x120-"
) => `${dataUrl}/${prefix}/${id}/${sizePrefix}${url}`;

export const testimonialimageUrl = (url, prefix = "products") =>
  `${dataUrl}/${prefix}/${url}`;

export const convertToSlug = (text) =>
  text
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");

export const getContentByName = (contents, name, defaultValue) =>
  contents.find((content) => content.name === name) || defaultValue;

export const getAssetsByName = (assets, name, defaultValue) =>
  assets.find((asset) => asset.name === name) || defaultValue;

export const getCategoryByName = (categories, name) => {
  return categories.find(
    (category) => category.name.toLowerCase() === name.toLowerCase()
  );
};


export const getCategoriesByParentName = (categories, parentName) => {
  let parentId = getCategoryByName(categories, parentName)?.id;
  return categories.filter(
    (category) => parseInt(category.parent_id) === parseInt(parentId)
  );
};

export const filterByCategoryName = (contents, categoryName, defaultValue) =>
  contents.filter((content) => content.category === categoryName) ||
  defaultValue;

export const getCategoryLink = (category) =>
  `/category/${category.id}/${category.url_slug}/1`;

export const getContentByCategoryName = (
  contents,
  categoryName,
  defaultValue
) =>
  contents.filter((content) => content.category === categoryName) || defaultValue;

export const getSettingByKey = (settings, keyName, defaultValue) =>
  settings?.find((setting) => setting.key === keyName) || defaultValue;

export function searchJobs(jobs, departments = ['All'], locations = ['All']) {

  // if (departments.includes('All') || locations.includes('All')) {
  //   return jobs;
  // }

  // if (departments.length === 0 && locations.length === 0) {
  //   return jobs;
  // }

  // if (departments.length > 0 && locations.length === 0) {
  //   return jobs.filter((job) => departments.includes(job.department));
  // }

  // if (departments.length === 0 && locations.length > 0) {
  //   return jobs.filter((job) => locations.includes(job.location));
  // }

  // if All departments and All locations are selected

  return jobs.filter(job => (departments.includes('All') || departments.includes(job.department)) && (locations.includes('All') || locations.includes(job.location)));
}

export const openInNewWindow = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const openUrlNewWindow = (menu) => {
  const url = menu?.url;
  if (menu?.open_in_new_window === "yes") {
    openInNewWindow(url);
  }
};

// getting splitted array from string by setting key
export function getSplittedArrayBySettingsKey(keyValue, settings) {
  const array = getSettingByKey(settings, keyValue)?.value;
  return keyValue ? toArray(array, "\n") : [];
}

export const getTestimonialByName = (
  clienttestimonials,
  company,
  defaultValue
) =>
  clienttestimonials.find(
    (clienttestimonial) => clienttestimonial.company === company
  ) || defaultValue;

export const getTestimonialByCompanyName = (clienttestimonials, companyName) =>
  clienttestimonials.filter(
    (clienttestimonial) => clienttestimonial.company === companyName
  );

export const getCategoryByParentId = (categories, parentId) =>
  categories.filter(
    (category) => parseInt(category.parent_id) === parseInt(parentId)
  );

// it is used to make the given string into array by splitting using the given seperator.
export const toArray = (value, seperator) => {
  let arr = [];

  if (value) {
    arr = value
      .split(seperator)
      .filter(Boolean)
      .map((v) => v.trim())
      .filter((v) => v !== "");
  }
  return arr;
};

// check img exists or not in the given url
export const checkImgExists = (url) => {
  var img = new Image();
  img.src = url;
};

//  check img exists or not in the given url
export function imageExists(image_url) {
  var http = new XMLHttpRequest();

  http.open("HEAD", image_url, false);
  http.send();

  return http.status !== 404;
}

// it is used to get the image url from the given html.
// export const getImageUrl = (html) => {
//     let img = html.match(/<img.*?src="(.*?)"/);
//     // let regex = /src="(.*?)"/i;
//     // let match = regex.exec(html);
//     return match[1];
// }

export const getUrlParams = (paramName, paramValue) => {
  // let url = 'https://avonnamkeen.com/category/10/namkeens/filter?price_range=100-500&taste=Sweet&weight=100';

  let url = document.location;
  var urlObj = new window.URL(url);
  urlObj.searchParams.set(paramName, paramValue);

  // returns => price_range=100-500&taste=Sweet&weight=100
  return urlObj.searchParams.toString();
};

export const removeUrlParam = (paramName) => {
  let url = document.location;
  var urlObj = new window.URL(url);
  urlObj.searchParams.delete(paramName);
  return urlObj.searchParams.toString();
};

export const getAppliedFilters = (paramName) => {
  let url = document.location;
  var urlObj = new window.URL(url);
  return urlObj.searchParams.get(paramName);
}

export const getFilterPath = (categoryId, categoryName, pageNumber, params) => {
  return `/category/${categoryId}/${categoryName}/${pageNumber}/filters?${params}`;
};

export const getImageUrl = (html) => {
  let img = html.match(/<img.*?src="(.*?)"/);
  return img[1];
};

export function getFirstCharacter(fullName) {
  let fname = fullName.replace(/\s+/g, " ").trim();
  fname = fname.split(" ");
  let firstNames = [];
  if (fname[0]) {
    if (fname[0][0]) {
      firstNames.push(fname[0][0]);
    }
  }
  if (fname[1]) {
    if (fname[1][0]) {
      firstNames.push(fname[1][0]);
    }
  }
  return firstNames.join("").toUpperCase();
}

export const customerId_local_storage_key = "teemuno_customerId";
export const authKey_local_storage_key = "teemuno_authToken";
export const cartData_local_storage_key = "teemuno_cart_data";
export const userInfo_local_storage_key = "teemuno_userInfo";
